import { render, staticRenderFns } from "./DetailTicketingCollaborator.html?vue&type=template&id=5f7767f8&"
import script from "./DetailTicketingCollaborator.js?vue&type=script&lang=js&"
export * from "./DetailTicketingCollaborator.js?vue&type=script&lang=js&"
import style0 from "./DetailTicketingCollaborator.scss?vue&type=style&index=0&lang=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports